import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import styled from "styled-components";
import RedirectPage from "./pages/RedirectPage";
import './App.css';

function App(props) {

  return (
    <BrowserRouter>
        <Routes>
          <Route index element={<RedirectPage />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
